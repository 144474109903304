import { useGoogleAnalytics, useToast } from '@/hooks';
import { waveformsClientPublic } from '@/utils/waveformsClientPublic';
import type { FC, FormEventHandler, PropsWithChildren } from 'react';

import { Toast } from '../Toast';

export const MailingListForm: FC<PropsWithChildren> = ({ children }) => {
  const ga = useGoogleAnalytics();
  const { register, showToast } = useToast();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    ga.events.subscribeToMailingList();

    const email = event.currentTarget.email.value;

    waveformsClientPublic.mailingList
      .add({ body: { email_address: email } })
      .then((data) => {
        if (data.success) {
          showToast('Thanks for signing up for our mailing list!');
        } else {
          showToast('Something went wrong. Please try again.');
        }
      })
      .catch(() => {
        showToast('Something went wrong. Please try again.');
      });
  };

  return (
    <>
      <Toast {...register()} />

      <form onSubmit={handleSubmit}>{children}</form>
    </>
  );
};
